import React from 'react'
import './App.css'
import { RouterProvider } from 'react-router-dom'
import { router } from './router'
import AuthProvider from './components/authProvider'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CloseBtn } from './components/ToastCloseBtn'
import { FinanceProvider } from './components/financeProvider'
import { BlackListProvider } from './components/blackListProvider'

export function App() {
  return (
    <React.StrictMode>
      <AuthProvider>
        <FinanceProvider>
          <BlackListProvider>
            <ToastContainer
              hideProgressBar={true}
              theme="dark"
              closeButton={CloseBtn}
            />
            <RouterProvider router={router} />
          </BlackListProvider>
        </FinanceProvider>
      </AuthProvider>
    </React.StrictMode>
  )
}

export default App
