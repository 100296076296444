import { Navigate, createBrowserRouter } from 'react-router-dom'
import Layout from './Layout'
import Pagos from './views/pagos/pagos'
import Protected from './components/protected'
import { Depositos } from './views/depositos/depositos'
import { BankAccountView } from './views/config/BankAccount'
import Error from './views/error/Error'
import { EditDeposit } from './views/depositos/edit'
import { QrView } from './views/config/bot/QR'
import { ContactNumbersView } from './views/config/bot/ContactNumbers'
import { Config } from './views/config/config'
import { Players } from './views/players/players'
import { EditPlayer } from './views/players/edit'
import { Analytics } from './views/analytics/analytics'
import { Login } from './views/Login'
import { BotChatsView } from './views/config/bot/Chats'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Navigate to="analiticas" />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'pagos',
        element: <Protected target={<Pagos />} />
      },
      {
        path: 'depositos',
        children: [
          {
            index: true,
            element: <Navigate to="lista" />
          },
          {
            path: 'lista',
            element: <Protected target={<Depositos />} />
          },
          {
            path: ':depositId/editar',
            element: <Protected target={<EditDeposit />} />
          }
        ]
      },
      {
        path: 'jugadores',
        children: [
          {
            index: true,
            element: <Navigate to="lista" />
          },
          {
            path: 'lista',
            element: <Protected target={<Players />} />
          },
          {
            path: ':playerId/editar',
            element: <Protected target={<EditPlayer />} />
          }
        ]
      },
      {
        path: 'bot',
        element: <Protected target={<BotChatsView />} />
      },
      {
        path: 'config',
        children: [
          {
            index: true,
            element: <Protected target={<Config />} />
          },
          {
            path: 'bot',
            children: [
              {
                index: true,
                element: <Navigate to="/config" />
              },
              {
                path: 'qr',
                element: <Protected target={<QrView />} />
              },
              {
                path: 'support',
                element: <Protected target={<ContactNumbersView />} />
              },
              {
                path: 'chats',
                element: <Protected target={<BotChatsView />} />
              }
            ]
          },
          {
            path: 'cuenta-bancaria',
            element: <Protected target={<BankAccountView />} />
          }
        ]
      },
      {
        path: 'analiticas',
        element: <Protected target={<Analytics />} />
      }
    ]
  }
])
