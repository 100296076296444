import { AnalyticsBox } from '../../components/AnalyticsBox'
import { mockCasinoBalance } from './mockData'

export function CasinoBalance() {
  return (
    <AnalyticsBox
      heading="Balance del casino"
      description="Fichas disponibles en el casino"
      value={mockCasinoBalance}
      color="#0bc"
    />
  )
}
