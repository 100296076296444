import styled from 'styled-components'

export const BoxHeading = styled.h2`
  font-size: 1.125rem;
  text-align: center;
`
export const BoxDescription = styled.p`
  text-align: center;
  font-size: 0.875rem !important;
`
export const BoxValue = styled.p`
  font-size: 2.5rem;
  text-align: center;
`
