import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { AuthContext } from '../../../components/authProvider'
import { ConfigItem } from '../../../components/ConfigItem'
import { GLOBAL_SWITCH_STATE } from '../../../config'
import { IAuthContext } from '../../../types/auth'

export function BotSwitch() {
  const { apiInit } = useContext(AuthContext) as IAuthContext
  const [_loading, setLoading] = useState(false)
  const [switchState, setswitchState] = useState<GLOBAL_SWITCH_STATE>()

  useEffect(() => {
    async function fetchGlobalSwitchStatus() {
      const api = await apiInit()
      const result = await api.get('/bot/switch')

      if (result.status === 200) {
        const state: GLOBAL_SWITCH_STATE =
          result.data.data || GLOBAL_SWITCH_STATE.ON
        setswitchState(state)
      }

      setLoading(false)
    }
    fetchGlobalSwitchStatus()
  }, [])

  const submitSwitch = async (state: GLOBAL_SWITCH_STATE) => {
    try {
      const api = await apiInit()
      const result = await api.post('/bot/switch', { state })
      if (result.status !== 200) throw result
      setswitchState(state)
    } catch (e) {
      toast('Algo salió mal, por favor volvé a intentar', { type: 'error' })
    }
  }

  const handleToggle: React.ChangeEventHandler<HTMLInputElement> = async (
    e
  ) => {
    setLoading(true)
    await submitSwitch(
      e.target.checked ? GLOBAL_SWITCH_STATE.ON : GLOBAL_SWITCH_STATE.OFF
    )
    setTimeout(() => setLoading(false), 200)
  }

  return (
    <ConfigItem
      title={switchState === GLOBAL_SWITCH_STATE.ON ? 'Encendido' : 'Apagado'}
      subtitle={'Enciende o apaga el bot para todos los usuarios.'}
      loading={_loading}
      toggleHandler={handleToggle}
      checked={switchState === GLOBAL_SWITCH_STATE.ON}
    />
  )
}
