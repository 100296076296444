import { AnalyticsBox } from '../../components/AnalyticsBox'
import { mockAvgTicket } from './mockData'

export function AverageTicket() {
  return (
    <AnalyticsBox
      heading="Ticket promedio"
      value={mockAvgTicket}
      color="#2cb"
    />
  )
}
