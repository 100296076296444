import { useCallback, useContext, useRef, useState } from 'react'
import { ViewHeader } from '../../../../components/ViewHeader'
import { Table } from '../../../../components/Table'
import { BotChat } from './BotChat'
import { BotHistory } from '../../../../types/botHistory'
import { TableData } from '../../../../types/table'
import { BlacklistContext } from '../../../../components/blackListProvider'

export function BotChatsView() {
  const [loading, setLoading] = useState(true)
  const { blacklist } = useContext(BlacklistContext)

  const filter = useCallback(
    (
      chats: TableData<BotHistory>,
      searchingBy: string,
      filteringBy?: string
    ) => {
      let result = chats.all

      if (filteringBy === 'blacklisted')
        result = chats.all.filter((c) => blacklist.includes(c.from))

      return result.filter(
        (c) =>
          c.from.toLowerCase().includes(searchingBy) ||
          c.created_at.toLowerCase().includes(searchingBy)
      )
    },
    [blacklist]
  )

  return (
    <>
      <ViewHeader loading={loading} heading={'Conversaciones'} />
      <Table
        RowElement={BotChat}
        entityNamePlural="conversaciones"
        searchPlaceholder="Buscar por número o mensaje"
        loading={loading}
        setLoading={setLoading}
        url="/bot-history"
        quickFilters={[
          { label: 'Todos', value: 'all', ref: useRef() },
          { label: 'Bot desactivado', value: 'blacklisted', ref: useRef() }
        ]}
        columns={[
          { name: 'teléfono', selector: 'from', type: 'string' },
          { name: 'último mensaje', selector: 'created_at', type: 'datetime' },
          { name: '🤖', selector: '' }
        ]}
        filter={filter}
      ></Table>
    </>
  )
}
