import { useContext, useEffect, useMemo, useState } from 'react'
import { AuthContext } from '../../components/authProvider'
import { AnalyticsSummary } from '../../types/analytics'
import { toast } from 'react-toastify'

export function useSummary() {
  const { apiInit } = useContext(AuthContext)
  const [summary, setSummary] = useState<AnalyticsSummary[]>()

  const fetchSummary = async () => {
    try {
      const api = await apiInit()
      const response = await api.get<{ data: AnalyticsSummary[] }>(
        '/analytics/summary'
      )

      if (response.status === 200) setSummary(response.data.data)
      else if (response.status === 401) toast.warn('Sesión expirada.')
      else if (response.status >= 500)
        toast.error('Error de servidor, intentá de nuevo en unos minutos.')
      else toast.error('No pudimos descargar el resumen de analiticas.')
    } catch (e) {
      setSummary([])
      toast.error('Error de conexión')
    }
  }

  useEffect(() => {
    fetchSummary()
  }, [])

  return useMemo(() => ({ summary }), [summary, apiInit])
}
