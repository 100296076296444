import { useState } from 'react'
import { ViewHeader } from '../../components/ViewHeader'
import { Analytic } from './analytic'
import { Table } from '../../components/Table'
import { Analytics as IAnalytics } from '../../types/analytics'
import { TableData } from '../../types/table'
import { AnalyticsCharts } from './charts'

export function Analytics() {
  const [loading, setLoading] = useState(false)

  const filter = (analytics: TableData<IAnalytics>, searchingBy: string) => {
    return analytics.all.filter(
      (a) =>
        a.source.toLowerCase().includes(searchingBy) ||
        a.event.toLowerCase().includes(searchingBy) ||
        JSON.stringify(a.data)?.toLowerCase().includes(searchingBy)
    )
  }

  return (
    <>
      <ViewHeader loading={loading} heading="Análisis de Tráfico" />

      <AnalyticsCharts />

      <h2>Lista</h2>
      <Table<IAnalytics>
        RowElement={Analytic}
        entityNamePlural="eventos"
        searchPlaceholder="Buscar por fuente, evento o data"
        loading={loading}
        setLoading={setLoading}
        url="/analytics"
        columns={[
          { selector: 'source', type: 'string', name: 'Fuente' },
          {
            selector: 'event',
            type: 'string',
            name: 'Evento'
          },
          { name: 'Data' },
          {
            selector: 'created_at',
            type: 'datetime',
            name: 'Fecha'
          }
        ]}
        filter={filter}
      />
    </>
  )
}
