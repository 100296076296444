import { useState } from 'react'
import { SortableColumnProps } from '../../../types/table'
import { CaretUpIcon } from '../../icons/caret-up'
import { THeadWrapper } from './styled'

export function SortableColumn(props: SortableColumnProps) {
  const { selector, type, name, handleSort, sortingBy } = props
  const [ascending, setAscending] = useState(sortingBy.ascending)

  const rotate = ascending ? 'rotate(180deg)' : 'rotate(0deg)'

  const color = selector === sortingBy.selector ? 'currentColor' : '#8889'
  return (
    <th
      onClick={() => {
        handleSort({
          selector,
          type,
          ascending: !ascending
        })
        setAscending(!ascending)
      }}
    >
      <THeadWrapper>
        <CaretUpIcon
          height={16}
          width={16}
          style={{
            transform: rotate,
            transition: 'transform .2s linear',
            fill: color,
            flexShrink: 0
          }}
        />
        {name}
      </THeadWrapper>
    </th>
  )
}
