import { Link } from 'react-router-dom'
import { ThreeDotsIcon } from '../../components/icons/three-dots'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../components/authProvider'
import { toast } from 'react-toastify'
import { PLAYER_STATUS } from '../../config'
import { Dialog } from '../../components/Dialog'
import { PlayerActionsProps, UpdatePlayerResponse } from '../../types/player'
import { showModal } from '../../components/Dialog/utils'
import { Details, Summary, A } from './styled'

export function PlayerActions({ player }: PlayerActionsProps) {
  const { apiInit } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(player.status)
  const [displayPasswordCopiedMessage, setDisplayPasswordCopiedMessage] =
    useState<boolean>(false)
  const dialogRef = useRef<HTMLDialogElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)
  const detailsRef = useRef<HTMLDetailsElement>(null)

  const banPlayer = async () => {
    detailsRef.current?.removeAttribute('open')
    setLoading!(true)
    try {
      const api = await apiInit()
      const res = await api.post<UpdatePlayerResponse>(
        `/players/${player.id}`,
        {
          status:
            status === PLAYER_STATUS.ACTIVE
              ? PLAYER_STATUS.BANNED
              : PLAYER_STATUS.ACTIVE
        }
      )
      if (res.status === 200) {
        toast.success('Cambios guardados')
        setStatus(res.data.data.status)
      } else if (res.status === 401) toast.warn('Sesión expirada.')
    } catch (e) {
      toast.error('Error de conexión')
    } finally {
      setLoading!(false)
    }
  }

  const submitPassword = useCallback(async (password: string) => {
    try {
      setLoading!(true)
      const api = await apiInit()
      const res = await api.post('/agent/reset-player-password', {
        new_password: password,
        user_id: player.id
      })
      if (res.status === 200) {
        toast.success('Contraseña guardada.')
      } else if (res.status === 401) toast.warn('Sesión expirada.')
      else if (res.status >= 500)
        toast.error('Error de servidor, intentá de nuevo en unos minutos')
      else toast.error('No pudimos cargar los datos')
    } catch (e) {
      toast.error('Error de conexión.')
    } finally {
      setLoading!(false)
    }
  }, [])

  useEffect(() => {
    const dialog = dialogRef.current
    if (!dialog) return

    dialog.onclose = async () => {
      if (!dialog.returnValue) return

      const password = passwordRef.current?.value
      if (!password || password.length < 4)
        return toast.error('La contraseña debe tener al menos 4 caracteres')

      submitPassword(password)

      detailsRef.current?.removeAttribute('open')
      passwordRef.current.value = ''
    }

    return () => {
      dialog.onclose = null
    }
  }, [dialogRef])

  const showPasswordModal = () => {
    const dialog = dialogRef.current
    if (!dialog) return
    showModal(dialog)
  }

  const generatePassword = () => {
    let arr = new Uint8Array(32)
    arr = crypto.getRandomValues(arr)

    // Ensure all values are between 33 and 125 (printable ASCII chars)
    arr.forEach((e, i) => {
      arr[i] = e % 125
      if (arr[i] < 35) arr[i] += 35
    })
    const password = String.fromCodePoint(...arr)
    const passwordInputElement = passwordRef.current
    if (!passwordInputElement) return

    passwordInputElement.value = password
    navigator.clipboard
      .writeText(password)
      .then(() => setDisplayPasswordCopiedMessage(true))
      .catch(() => {})

    setTimeout(() => {
      setDisplayPasswordCopiedMessage(false)
    }, 6000)
  }

  return (
    <Details className="dropdown" ref={detailsRef}>
      <Dialog heading="Cambiar contraseña" dialogRef={dialogRef}>
        <p>Jugador: {player.username}</p>
        <fieldset role="group">
          <input
            type="text"
            name="password"
            minLength={4}
            placeholder="contraseña"
            ref={passwordRef}
          />
          <button className="outline contrast" onClick={generatePassword}>
            Generar
          </button>
        </fieldset>

        <small
          style={{
            color: 'var(--pico-form-element-valid-active-border-color)',
            visibility: displayPasswordCopiedMessage ? 'visible' : 'hidden'
          }}
        >
          Contraseña copiada
        </small>
      </Dialog>
      <Summary aria-busy={loading}>
        {(!loading && (
          <ThreeDotsIcon
            height={35}
            width={35}
            style={{ cursor: 'pointer', rotate: '90deg', padding: '.25rem' }}
          />
        )) || <span>&nbsp;</span>}
      </Summary>
      <ul dir="rtl">
        <li>
          <Link to={`/jugadores/${player.id}/editar`}>🖊 Editar</Link>
        </li>
        <li>
          <A onClick={banPlayer} aria-busy={loading}>
            {status === PLAYER_STATUS.ACTIVE ? '🔒 Bloquear' : '🔓 Desbloquear'}
          </A>
        </li>
        <li>
          <A onClick={showPasswordModal}>🔄 Cambiar contraseña</A>
        </li>
      </ul>
    </Details>
  )
}
