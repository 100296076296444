export function parseDepositStatus(status: string) {
  let translatedStatus = ''
  let description = ''
  switch (status) {
    case 'pending':
      translatedStatus = 'Pendiente'
      description = 'Aún no se intentó verificar el depósito.'
      break
    case 'unverified':
      translatedStatus = 'No verificado'
      description = 'No se pudo confirmar la recepción del depósito.'
      break
    case 'verified':
      translatedStatus = 'Verificado'
      description = 'Recepción del depósito confirmada.'
      break
    case 'deleted':
      translatedStatus = 'Eliminado'
      description = 'Eliminado por el agente.'
      break
  }
  return { translatedStatus, description }
}
