import { useState, useRef, useContext, useCallback, useEffect } from 'react'
import { AuthContext } from '../authProvider'
import { sortColumn } from '../table-utilities/sort'
import { toast } from 'react-toastify'
import { SegmentSelect } from '../SegmentSelect'
import { TableNavigation } from '../table-utilities/TableNavigation'
import {
  ApiResponse,
  SortingBy,
  TableData,
  TableProps
} from '../../types/table'
import { SortableColumn } from '../table-utilities/SortableColumn'
import { IAuthContext } from '../../types/auth'
import { useTableNavigation } from './useTableNavigation'

export function Table<T extends { id: string }>({
  url,
  entityNamePlural,
  defaultFilter,
  quickFilters,
  RowElement,
  loading,
  setLoading,
  ExtraTableHeadRow,
  columns,
  filter,
  searchPlaceholder
}: TableProps) {
  const searchInput = useRef<HTMLInputElement>(null)
  const [data, setData] = useState<TableData<T>>({ all: [], display: [] })
  const [totalData, setTotalData] = useState(0)
  const [filteringBy, setFitleringBy] = useState<string>(defaultFilter ?? '')
  const {
    fullUrl,
    page,
    itemsPerPage,
    searchingBy,
    sortingBy,
    setPage,
    setItemsPerPage,
    setSearchingBy,
    setSortingBy
  } = useTableNavigation(url)
  const { apiInit } = useContext(AuthContext) as IAuthContext

  const fetchData = useCallback(async () => {
    try {
      const api = await apiInit()
      const res = await api.get<ApiResponse<T>>(fullUrl)

      if (res.status === 200) {
        const { result } = res.data.data
        setData((prev) =>
          prev.all === result ? prev : { all: result, display: prev.display }
        )
        setTotalData(res.data.data.total)
      } else if (res.status === 401) toast.warn('Sesión expirada.')
      else if (res.status >= 500)
        toast.error('Error de servidor, intentá de nuevo en unos minutos.')
      else toast.error(`No pudimos descargar los ${entityNamePlural}`)
    } catch (e: any) {
      toast.error('Error de conexión')
    } finally {
      setLoading(false)
    }
  }, [fullUrl, filteringBy])

  useEffect(() => {
    setLoading(true)
    const timeoutId = setTimeout(fetchData, 500)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [fetchData])

  const handleSearchInput = () => {
    const q = (searchInput.current as HTMLInputElement).value.toLowerCase()
    setSearchingBy(q)
  }

  const sortHandler = (sortingBy: SortingBy) => {
    setSortingBy(sortingBy)
    setData((prev) => ({
      all: prev.all,
      display: prev.display.sort((a, b) =>
        sortColumn(
          a as Record<string, any>,
          b as Record<string, any>,
          sortingBy
        )
      )
    }))
  }

  const handleSegmentSelect = (status: string) => {
    setFitleringBy(status)
  }

  useEffect(() => {
    if (!data.all) return
    const filtered = filter(data, searchingBy, filteringBy).sort(
      (a, b) => sortColumn(a, b, sortingBy) as number
    )
    setData((prev) => ({ all: prev.all, display: filtered }))
  }, [filteringBy, sortingBy, searchingBy, data.all])

  return (
    <>
      <input
        type="search"
        id="search"
        name="search"
        placeholder={searchPlaceholder}
        onKeyUp={handleSearchInput}
        ref={searchInput}
      />
      {quickFilters && (
        <SegmentSelect
          name={'data-filter'}
          segments={quickFilters}
          callback={handleSegmentSelect}
          defaultIndex={0}
          controlRef={useRef()}
        />
      )}
      <TableNavigation
        total={totalData}
        page={page}
        setPage={setPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
      />
      <figure className="overflow-auto">
        <table>
          <thead>
            {ExtraTableHeadRow}
            <tr>
              {columns.map((c) =>
                c.selector && c.type ? (
                  <SortableColumn
                    selector={c.selector}
                    type={c.type}
                    sortingBy={sortingBy}
                    name={c.name}
                    handleSort={sortHandler}
                    key={c.selector}
                  />
                ) : (
                  <th key={c.name}>{c.name}</th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {data.display.length > 0 ? (
              data.display.map((p) => <RowElement data={p} key={p.id} />)
            ) : (
              <tr>
                <td colSpan={7}>
                  <span style={{ fontSize: '1.5rem' }}>🏜</span>
                  &nbsp;{loading ? 'Buscando...' : `No hay ${entityNamePlural}`}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </figure>
    </>
  )
}
