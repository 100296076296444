import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Description = styled.p`
  margin: 0;
  padding: 0;
  &:hover {
    --pico-text-decoration: none;
  }
`
export const Action = styled.div`
  display: grid;
  place-content: center;
  // cursor: pointer;
`
export const Subtitle = styled.small`
  opacity: 0.75;
`
