import { useMemo, useState } from 'react'
import { SortingBy } from '../../types/table'

export function useTableNavigation(baseUrl: string) {
  const [sortingBy, setSortingBy] = useState<SortingBy>({
    selector: 'created_at',
    type: 'datetime',
    ascending: false
  })
  const [searchingBy, setSearchingBy] = useState<string>('')
  const [page, setPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(20)

  const fullUrl =
    baseUrl +
    '?page=' +
    page +
    '&items_per_page=' +
    itemsPerPage +
    '&search=' +
    searchingBy +
    '&sort_column=' +
    sortingBy.selector +
    '&sort_direction=' +
    (sortingBy.ascending ? 'asc' : 'desc')

  return useMemo(
    () => ({
      fullUrl,
      sortingBy,
      setSortingBy,
      searchingBy,
      setSearchingBy,
      page,
      setPage,
      itemsPerPage,
      setItemsPerPage
    }),
    [baseUrl, page, itemsPerPage, searchingBy]
  )
}
