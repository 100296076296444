import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Deposit } from '../types/deposit'
import { AuthContext } from './authProvider'
import { config } from '../config'
import {
  FinanceProvierProps,
  PendingCoinTransfersResponse
} from '../types/financeProvider'

export interface IFinanceContext {
  pendingCoinTransfers: number
  freePendingTransfers: () => Promise<void>
  fetchPendingCoinTransfers: () => Promise<void>
  autoPayments: boolean
  setAutoPayments: Dispatch<React.SetStateAction<boolean>>
}

export const FinanceContext = createContext({} as IFinanceContext)
export function FinanceProvider({ children }: FinanceProvierProps) {
  const { apiInit, isAuthed } = useContext(AuthContext)
  const [pendingCoinTransfers, setPendingCoinTransfers] = useState(0)
  const [autoPayments, setAutoPayments] = useState(false)

  const fetchPendingCoinTransfers = useCallback(async () => {
    const url = '/coin-transfer/pending-total'
    const api = await apiInit()
    const res = await api.get<PendingCoinTransfersResponse>(url)

    if (res.status === 200) {
      setPendingCoinTransfers(res.data.data)
    }
  }, [apiInit])

  const freePendingTransfers = useCallback(() => {
    return apiInit()
      .then((api) => api.get('/coin-transfer/release-pending'))
      .then((res) => {
        if (res.status === 200) return res.data.data as Deposit[]
        throw res
      })
      .then((_fresh) => {
        // TODO
        // Dispatch event
      })
      .catch(() => {})
  }, [apiInit])

  useEffect(() => {
    if (isAuthed) fetchPendingCoinTransfers()
  }, [isAuthed])

  useEffect(() => {
    if (!('serviceWorker' in navigator)) return
    navigator.serviceWorker.onmessage = (e) => {
      if (e.data.msg === config.SD.INSUFICIENT_CREDITS)
        fetchPendingCoinTransfers()
    }

    return () => {
      navigator.serviceWorker.onmessage = null
    }
  }, [])

  const financeContextValue = useMemo(
    () => ({
      pendingCoinTransfers,
      freePendingTransfers,
      fetchPendingCoinTransfers,
      autoPayments,
      setAutoPayments
    }),
    [
      pendingCoinTransfers,
      freePendingTransfers,
      fetchPendingCoinTransfers,
      autoPayments,
      setAutoPayments
    ]
  )
  return (
    <FinanceContext.Provider value={financeContextValue}>
      {children}
    </FinanceContext.Provider>
  )
}
