import { AverageTicket } from './averageTicket'
import { CasinoBalance } from './casinoBalance'
import { DepositsPaymentsChart } from './depositsChart'
import { NewUsersChart } from './newUsersChart'
import { PageVisits } from './pageVisits'
import { AnalyticsSummary } from './summary'
import { BankBalance } from './bankBalance'

/*
Usuarios Nuevos  ✅
Ultimos Depositos  ✅
Ultimos Pagos  ✅
Visitas a la pagina web  ✅
Ticket Promedio  ✅
Fichas en stock  ✅
Saldo en el banco  
*/

export function AnalyticsCharts() {
  return (
    <>
      <div className="grid">
        <article>
          <PageVisits />
        </article>
        <article>
          <AverageTicket />
        </article>
        <article>
          <CasinoBalance />
        </article>
        <article>
          <BankBalance />
        </article>
      </div>

      <div className="grid">
        <article>
          <AnalyticsSummary />
        </article>
        <article>
          <NewUsersChart />
        </article>
      </div>

      <article>
        <DepositsPaymentsChart />
      </article>
    </>
  )
}
