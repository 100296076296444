import { parseDate } from '../../../../components/table-utilities/parse-date'
import { BotHistory } from '../../../../types/botHistory'
import { ToggleBot } from './ToggleBot'

export function BotChat({ data }: { data: BotHistory }) {
  return (
    <tr>
      <td>{data.from}</td>
      <td>{parseDate(data.created_at)}</td>
      <td>
        <ToggleBot data={data} />
      </td>
    </tr>
  )
}
