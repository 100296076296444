import { AnalyticsBox } from '../../components/AnalyticsBox'
import { mockBankBalance } from './mockData'

export function BankBalance() {
  return (
    <AnalyticsBox
      heading="Saldo en el banco"
      value={mockBankBalance}
      color="#e94"
    />
  )
}
