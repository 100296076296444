import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { mockFinance } from './mockData'
import { useWindowSize } from '@uidotdev/usehooks'

export function DepositsPaymentsChart() {
  const { width: windowWidth } = useWindowSize()
  const chartWitdh = windowWidth ? Math.min(windowWidth * 0.9, 1090) : 1000

  return (
    <LineChart width={chartWitdh} height={350} data={mockFinance}>
      <CartesianGrid strokeDasharray="4 4" stroke="#7775" />
      <XAxis dataKey="name" stroke="var(--pico-color)" />
      <YAxis stroke="var(--pico-color)" />
      <Line
        type="monotone"
        dataKey="depositos"
        stroke="#9d5"
        strokeWidth={2}
        activeDot={{ r: 6 }}
      />
      <Line type="monotone" dataKey="retiros" stroke="#e94" strokeWidth={2} />
      <Legend />
      <Tooltip />
    </LineChart>
  )
}
