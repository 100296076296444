import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { APIResponse } from '../types/api'
import { AuthContext } from './authProvider'

export const BlacklistContext = createContext<{
  blacklist: string[]
  updateBlacklist: () => Promise<void>
}>({
  blacklist: [],
  updateBlacklist: () => Promise.resolve()
})

export function BlackListProvider({ children }: { children: ReactNode }) {
  const [blacklist, setBlacklist] = useState<string[]>([])
  const { apiInit, isAuthed } = useContext(AuthContext)

  async function fetchBlacklist() {
    const api = await apiInit()
    const listResponse = await api.get<APIResponse<string[]>>('/bot/blacklist')

    if (listResponse.status !== 200) return

    setBlacklist(listResponse.data.data)
  }

  useEffect(() => {
    if (isAuthed) fetchBlacklist()
  }, [])

  const blacklistContextValue = useMemo(
    () => ({ blacklist, updateBlacklist: fetchBlacklist }),
    [blacklist]
  )

  return (
    <BlacklistContext.Provider value={blacklistContextValue}>
      {children}
    </BlacklistContext.Provider>
  )
}
