import {
  ChangeEventHandler,
  FormEvent,
  FormEventHandler,
  useContext,
  useEffect,
  useState
} from 'react'
import { ViewHeader } from '../../components/ViewHeader'
import { Link, useParams } from 'react-router-dom'
import { AuthContext } from '../../components/authProvider'
import { toast } from 'react-toastify'
import { Player, PlayerResponse, PlayerUpdateRequest } from '../../types/player'
import { Fieldset, Input } from './styled'
import { FormFooter } from '../../components/FormFooter'

export function EditPlayer() {
  const { playerId } = useParams()
  const { apiInit } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [player, setPlayer] = useState<Player>()
  const [playerUpdateRequest, setPlayerUpdateRequest] =
    useState<PlayerUpdateRequest>()

  const fetchPlayer = async () => {
    try {
      const api = await apiInit()
      const url = `/players/${playerId}`
      const res = await api.get<PlayerResponse>(url)

      if (res.status === 200) setPlayer(res.data.data[0])
      else if (res.status === 401) toast.warn('Sesión expirada.')
      else if (res.status >= 500)
        toast.error('Error de servidor, intentá de nuevo en unos minutos.')
      else toast.error('No pudimos cargar los datos.')
    } catch (e) {
      toast.error('Error de conexión.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchPlayer()
  }, [])

  useEffect(() => {
    setPlayerUpdateRequest({
      email: player?.email ?? '',
      movile_number: player?.movile_number ?? '',
      first_name: player?.first_name ?? '',
      last_name: player?.last_name ?? ''
    })
  }, [player])

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const api = await apiInit()
      const res = await api.post<PlayerResponse>(
        `/players/${playerId}`,
        playerUpdateRequest
      )
      if (res.status === 200) {
        setPlayer(res.data.data[0])
        toast.success('Cambios guardados')
      } else if (res.status === 401) toast.warn('Sesión expirada.')
      else if (res.status >= 500)
        toast.error('Error de servidor, intentá de nuevo en unos minutos')
      else toast.error('No pudimos guardar los cambios')
    } catch (err: any) {
      toast.error('Error de conexión')
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const handleChange: ChangeEventHandler<HTMLFieldSetElement> = (e) => {
    e.target.setCustomValidity('')
    e.target.removeAttribute('aria-invalid')
    setPlayer((prev) => {
      if (!prev) return
      return {
        ...prev,
        // @ts-ignore
        [e.target.name]: e.target.value ?? ''
      }
    })
  }

  const handleInvalidPhone = (e: FormEvent<HTMLInputElement>) => {
    ;(e.target as HTMLInputElement).setCustomValidity(
      'El teléfono tiene que tener solo números y ser de 20 caracteres o menos.'
    )
    ;(e.target as HTMLInputElement).setAttribute('aria-invalid', 'true')
  }

  return (
    <>
      <ViewHeader loading={loading} heading={'Editar jugador'} />
      <form onSubmit={handleSubmit}>
        <Fieldset className="grid">
          <label>
            ID
            <Input type="text" readOnly value={player?.id ?? ''} />
          </label>
          <label>
            Usuario
            <Input type="text" value={player?.username ?? ''} readOnly />
          </label>
        </Fieldset>

        <Fieldset className="grid" onChange={handleChange}>
          <label>
            email
            <input
              name="email"
              type="text"
              // readOnly
              value={player?.email ?? ''}
            />
          </label>
          <label>
            Teléfono
            <input
              inputMode="numeric"
              name="movile_number"
              type="text"
              value={player?.movile_number ?? ''}
              maxLength={20}
              pattern="[0-9]+"
              onInvalid={handleInvalidPhone}
            />
            <small>Con código de pais, sin + y sin espacios</small>
          </label>
        </Fieldset>
        <Fieldset className="grid" onChange={handleChange}>
          <label>
            Nombre
            <input
              name="first_name"
              type="text"
              value={player?.first_name ?? ''}
            />
          </label>
          <label>
            Apellido
            <input
              name="last_name"
              type="text"
              value={player?.last_name ?? ''}
            />
          </label>
        </Fieldset>
        <label>
          Fecha de nacimiento
          <Input type="text" readOnly value={player?.date_of_birth ?? ''} />
        </label>
        <label>
          Pais
          <Input type="text" readOnly value={player?.country ?? ''} />
        </label>
        <label>
          Estado
          <Input type="text" readOnly value={player?.status ?? ''} />
        </label>

        <FormFooter>
          <Link to=".." style={{ justifySelf: 'flex-start' }}>
            <button type="button" className="secondary">
              Volver
            </button>
          </Link>
          <button type="submit" value="Verificar">
            Guardar
          </button>
        </FormFooter>
      </form>
    </>
  )
}
