// value: [1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89]
export const mockNewUsers = [
  {
    name: 'Feb',
    'Usuarios nuevos': 26
  },
  {
    name: 'Mar',
    'Usuarios nuevos': 31
  },
  {
    name: 'Abr',
    'Usuarios nuevos': 33
  },
  {
    name: 'May',
    'Usuarios nuevos': 28
  },
  {
    name: 'Jun',
    'Usuarios nuevos': 29
  },
  {
    name: 'Jul',
    'Usuarios nuevos': 33
  },
  {
    name: 'Ago',
    'Usuarios nuevos': 38
  },
  {
    name: 'Sep',
    'Usuarios nuevos': 40
  },
  {
    name: 'Oct',
    'Usuarios nuevos': 37
  },
  {
    name: 'Nov',
    'Usuarios nuevos': 45
  },
  {
    name: 'Dic',
    'Usuarios nuevos': 57
  }
]

export const mockFinance = [
  {
    name: '04/10',
    depositos: 4000,
    retiros: 580
  },
  {
    name: '05/10',
    depositos: 3000,
    retiros: 780
  },
  {
    name: '06/10',
    depositos: 2000,
    retiros: 980
  },
  {
    name: '07/10',
    depositos: 2780,
    retiros: 1908
  },
  {
    name: '08/10',
    depositos: 1890,
    retiros: 800
  },
  {
    name: '09/10',
    depositos: 1190,
    retiros: 1800
  },
  {
    name: '10/10',
    depositos: 3490,
    retiros: 800
  },
  {
    name: '11/10',
    depositos: 3490,
    retiros: 250
  },
  {
    name: '12/10',
    depositos: 3490,
    retiros: 1350
  },
  {
    name: '13/10',
    depositos: 3750,
    retiros: 1850
  }
]

export const mockPageVisits = 150

export const mockAvgTicket = 500

export const mockCasinoBalance = 15780

export const mockBankBalance = 755
