import axios from 'axios'
import { createContext, useState, useCallback, useMemo, useEffect } from 'react'
import { config } from '../config'
import { toast } from 'react-toastify'
import { redirect } from 'react-router-dom'
import { TokenPair, AuthProviderProps } from '../types/auth'

const defaultContext = {
  getToken: (): Promise<string | null> => Promise.resolve(null),
  isAuthed: false,
  login: (_tokens: TokenPair) => {},
  logout: () => {},
  apiInit: () => Promise.resolve(axios.create())
}
export const AuthContext = createContext(defaultContext)

export default function AuthProvider({ children }: AuthProviderProps) {
  const [token, setToken] = useState<TokenPair | null>()

  /**
   * Store tokens in localstorage and memory
   */
  const login = useCallback((tokens: TokenPair) => {
    localStorage.setItem(config.ACCESS_TOKEN_KEY, tokens.access)
    localStorage.setItem(config.REFRESH_TOKEN_KEY, tokens.refresh)
    setToken(tokens)
  }, [])

  /**
   * Remove tokens from storage and memory
   */
  const logout = useCallback(async () => {
    localStorage.removeItem(config.ACCESS_TOKEN_KEY)
    localStorage.removeItem(config.REFRESH_TOKEN_KEY)
    setToken(null)
    const api = await apiInit()
    api.post('/auth/logout', { token: await getToken() }).catch(() => {})
  }, [])

  /**
   * Chequea la validez del token en memoria o localStorage
   */
  const isAuthed = useCallback(() => {
    if (token) {
      return (
        checkTokenExpiration(token.access) ||
        checkTokenExpiration(token.refresh)
      )
    }

    const access = localStorage.getItem(config.ACCESS_TOKEN_KEY)
    const refresh = localStorage.getItem(config.REFRESH_TOKEN_KEY)
    if (!access && !refresh) return false
    const authed = checkTokenExpiration(access) || checkTokenExpiration(refresh)
    return authed
  }, [token])

  const refreshToken = useCallback(
    (refresh: string) => {
      return axios
        .post(config.BASE_URL + '/auth/refresh', { token: refresh })
        .then((res) => {
          if (res.status === 200) {
            const tokens: TokenPair = res.data.data
            login(tokens)
            return tokens
          }
          throw res
        })
        .catch(() => {
          toast('Algo salió mal, por favor volvé a iniciar sesión', {
            type: 'error'
          })
          logout()
          redirect('/login')
        })
    },
    [login, logout]
  )

  /**
   * Devuelve un access token, refresca si es necesario.
   */
  const getToken = useCallback(async () => {
    if (!isAuthed()) return null
    const access =
      token?.access || localStorage.getItem(config.ACCESS_TOKEN_KEY)
    if (checkTokenExpiration(access)) return access

    const refresh =
      token?.refresh || localStorage.getItem(config.REFRESH_TOKEN_KEY)
    if (!refresh || !checkTokenExpiration(refresh)) return null

    const refreshed = await refreshToken(refresh)
    if (!refreshed) return null

    setToken(refreshed)
    return refreshed.access
  }, [isAuthed, token, refreshToken])

  const controller = new AbortController()
  const axiosBase = axios.create({
    baseURL: config.BASE_URL,
    validateStatus: (status) => status !== 401,
    signal: controller.signal,
    withCredentials: false
  })

  const isUnauthorized = useCallback((err: any) => {
    return (
      err.response?.status === 401 &&
      (err.response?.data.code === 'token_invalido' ||
        err.response?.data.code === 'Unauthorized')
    )
  }, [])

  const handleTokenRevocation = useCallback(async (err: any) => {
    if (isUnauthorized(err) && !err.request.responseURL.includes('/logout')) {
      await logout()
      controller.abort()
      return err
    } else return err
  }, [])

  /**
   * Inject Bearer token and response interceptor into axios instance
   */
  const apiInit = useCallback(async () => {
    axiosBase.defaults.headers.common['Authorization'] =
      'Bearer ' + (await getToken())
    axiosBase.interceptors.response.use((res) => res, handleTokenRevocation)
    return axiosBase
  }, [axiosBase, getToken])

  const authContextValue = useMemo(
    () => ({
      getToken,
      get isAuthed() {
        return isAuthed()
      },
      login,
      logout,
      apiInit
    }),
    [getToken, isAuthed, login, logout, apiInit]
  )

  /**
   * Check auth status when user returns to window
   */
  useEffect(() => {
    window.onfocus = () => {
      if (!isAuthed() && window.location.pathname !== '/login') {
        window.location.assign('/login')
      }
    }

    return () => {
      window.onfocus = null
    }
  }, [])

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  )
}

function checkTokenExpiration(token: string | null) {
  if (!token) return false
  const encodedPayload = token.split('.')[1]
  const payload = JSON.parse(atob(encodedPayload))

  return payload.exp > Math.floor(Date.now() / 1000)
}
