import { ChevronIcon } from '../icons/chevron'
import { Link } from 'react-router-dom'
import { Action, Description, Main, Subtitle } from './styled'
import { ConfigItemProps } from '../../types/config'

export function ConfigItem(props: ConfigItemProps) {
  const { title, subtitle, loading, toggleHandler, checked, redirect } = props
  return (
    <>
      {redirect && (
        <Link
          to={redirect}
          style={{
            // @ts-ignore
            '--pico-text-decoration': 'none',
            '--pico-color': 'inherit'
          }}
        >
          <Main>
            <Description>
              {title}&nbsp;<span aria-busy={loading}></span>
              <br />
              <Subtitle>{subtitle}</Subtitle>
            </Description>
            <Action>
              <ChevronIcon width={20} height={20} />
            </Action>
          </Main>
        </Link>
      )}
      {toggleHandler && (
        <Main>
          <Description>
            {title}&nbsp;<span aria-busy={loading}></span>
            <br />
            <Subtitle>{subtitle}</Subtitle>
          </Description>
          <Action>
            <input
              type="checkbox"
              role="switch"
              onChange={toggleHandler}
              value={1}
              checked={checked}
              disabled={loading}
            />
          </Action>
        </Main>
      )}
    </>
  )
}
