import { ChangeEventHandler, useContext, useEffect, useState } from 'react'
import { BotHistory } from '../../../../types/botHistory'
import { AuthContext } from '../../../../components/authProvider'
import { BLACKLIST_METHOD } from '../../../../config'
import { toast } from 'react-toastify'
import { BlacklistContext } from '../../../../components/blackListProvider'

export function ToggleBot({ data }: { data: BotHistory }) {
  const [isBlacklisted, setIsBlacklisted] = useState(false)
  const [loading, setLoading] = useState(false)
  const { apiInit } = useContext(AuthContext)
  const { blacklist, updateBlacklist } = useContext(BlacklistContext)

  useEffect(() => {
    if (blacklist?.length) setIsBlacklisted(blacklist.includes(data.from))
  }, [blacklist])

  const handleChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    setLoading(true)
    const api = await apiInit()
    try {
      const response = await api.post('/bot/blacklist', {
        method: isBlacklisted ? BLACKLIST_METHOD.REMOVE : BLACKLIST_METHOD.ADD,
        number: data.from
      })

      if (response.status === 200) setIsBlacklisted(!isBlacklisted)
      else if (response.status >= 400 && response.status < 500)
        toast.error('No pudimos agregar el número a la lista negra.')
      else if (response.status >= 500) {
        toast.error('Error en el servidor.')
      }
    } catch (e) {
      toast.error('Algo salió mal, por favor intenta de nuevo.')
    } finally {
      setLoading(false)
      updateBlacklist()
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <input
        type="checkbox"
        role="switch"
        checked={!isBlacklisted}
        onChange={handleChange}
        disabled={loading}
      />
      <span
        aria-busy={loading}
        style={{ position: 'absolute', right: '0' }}
      ></span>
    </div>
  )
}
