import { AnalyticsBox } from '../../components/AnalyticsBox'
import { mockPageVisits } from './mockData'

export function PageVisits() {
  return (
    <AnalyticsBox
      heading="Visitas a la página"
      description="Número de usuarios que ingresaron hoy al casino"
      color="#4d8"
      value={mockPageVisits}
    />
  )
}
