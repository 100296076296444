import { ConfigItem } from '../../../../components/ConfigItem'

export function Qr() {
  return (
    <ConfigItem
      title={'QR'}
      subtitle={'Para vincular bots WhatsApp.'}
      redirect="bot/qr"
    />
  )
}
