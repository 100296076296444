import { AnalyticsBoxProps } from '../../types/analytics'
import { BoxHeading, BoxDescription, BoxValue } from './styled'

export function AnalyticsBox({
  heading,
  description,
  value,
  color
}: AnalyticsBoxProps) {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%'
        }}
      >
        <hgroup>
          <BoxHeading>{heading}</BoxHeading>
          {description && <BoxDescription>{description}</BoxDescription>}
        </hgroup>
        <BoxValue>
          <span style={{ color }}>{value}</span>
        </BoxValue>
      </div>
    </>
  )
}
