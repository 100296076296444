import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { mockNewUsers } from './mockData'
import { useWindowSize } from '@uidotdev/usehooks'

export function NewUsersChart() {
  const { width: windowWidth } = useWindowSize()
  let chartWidth = 450
  if (windowWidth) {
    const breakpoint = 768
    const isMobile = windowWidth < breakpoint
    const containerWidth = isMobile ? windowWidth * 0.9 : windowWidth * 0.4
    chartWidth = Math.min(containerWidth, isMobile ? 1090 : 500)
  }

  return (
    <LineChart width={chartWidth} height={350} data={mockNewUsers}>
      <CartesianGrid strokeDasharray="4 4" stroke="#7775" />
      <XAxis dataKey="name" stroke="var(--pico-color)" />
      <YAxis stroke="var(--pico-color)" />
      <Line
        type="monotone"
        dataKey="Usuarios nuevos"
        stroke="#a35"
        strokeWidth={2.125}
        activeDot={{ r: 6 }}
      />
      <Legend />
      <Tooltip />
    </LineChart>
  )
}
